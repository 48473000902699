class ArPackage {
  accountNum
  createAdminName
  createTime
  createUserid
  id
  name
  pic
  price
  resourceList
  updateTime
  userList

  constructor (params) {
    const {
      accountNum,
      createAdminName,
      createTime,
      createUserid,
      id,
      name,
      pic,
      price,
      resourceList,
      updateTime,
      userList,
    } = params ?? {}

    this.accountNum = accountNum
    this.createAdminName = createAdminName
    this.createTime = createTime
    this.createUserid = createUserid
    this.id = id
    this.name = name
    this.pic = pic
    this.price = price
    this.resourceList = resourceList
    this.updateTime = updateTime
    this.userList = userList
  }
}

export default ArPackage
