var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "v_ar_edit_asset_list",
    },
    [
      0
        ? _c("div", { staticClass: "upload_group" }, [
            _c("div", { staticClass: "upload_btn btn_dark" }, [
              _vm._v("\n      点击上传\n    "),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "type_list d-flex align-center justify-space-between" },
        [
          _vm._l(_vm.assetType, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "type_group d-flex align-center justify-center flex-column cp",
                  class: {
                    [item.type]: true,
                    on: item.type === _vm.type,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.touchType(item.type)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "icon" }),
                  _c("div", { staticClass: "name" }, [
                    _vm._v("\n          " + _vm._s(item.name) + "\n        "),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      1
        ? _c(
            "div",
            {
              staticClass:
                "filter_group d-flex align-center justify-space-between flex-shrink-0",
            },
            [
              _c(
                "div",
                {
                  staticClass: "public tag",
                  class: {
                    active: _vm.filterType === 0,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeFilterType(0)
                    },
                  },
                },
                [_vm._v("\n      公共资产\n    ")]
              ),
              _c(
                "div",
                {
                  staticClass: "my tag",
                  class: {
                    active: _vm.filterType === 1,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeFilterType(1)
                    },
                  },
                },
                [_vm._v("\n      我的资产\n    ")]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.filterType,
              expression: "!filterType",
            },
          ],
          staticClass: "package_list_group",
        },
        [
          _c(
            "el-select",
            {
              staticClass: "package_list",
              attrs: { placeholder: "请选择", size: "mini" },
              on: { change: _vm.changePackage },
              model: {
                value: _vm.filterValue,
                callback: function ($$v) {
                  _vm.filterValue = $$v
                },
                expression: "filterValue",
              },
            },
            _vm._l(_vm.arPackageList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "asset_list_group d-flex flex-wrap align-center justify-space-between",
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.filterType,
                expression: "filterType",
              },
            ],
            staticClass: "add_asset_group cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.touchEditAsset.apply(null, arguments)
              },
            },
          }),
          _vm._l(_vm.assetList, function (item, index) {
            return [
              _c(
                "div",
                { key: index, staticClass: "asset_group" },
                [
                  _c("AssetComponent", {
                    attrs: { "ar-asset": item },
                    on: { touchDeleteHandler: _vm.touchDeleteHandler },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.touchAsset(item)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm.hasMore
        ? _c("div", { staticClass: "more_group" }, [
            _c(
              "div",
              {
                staticClass: "more cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.loadMore.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n      加载更多\n    ")]
            ),
          ])
        : _vm._e(),
      _c(
        "modal",
        { attrs: { name: "editAsset", height: "auto", width: "400px" } },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "上传资产" },
              on: { closeHandler: _vm.closeEditAsset },
            },
            [
              _c("EditAsset", {
                attrs: { "input-type": _vm.typeNumber },
                on: {
                  closeHandler: _vm.closeEditAsset,
                  reloadHandler: _vm.reloadPersonalList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        { attrs: { name: "deleteAsset", height: "auto", width: "400px" } },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "删除资产" },
              on: { closeHandler: _vm.closeDeleteAsset },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingDelete,
                      expression: "loadingDelete",
                    },
                  ],
                  staticClass: "delete_asset_group",
                },
                [
                  _c("div", { staticClass: "desc" }, [
                    _vm._v("\n          是否确认删除?\n        "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "fun_group d-flex align-center justify-center",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "btn btn_light",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.closeDeleteAsset.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n            取消\n          ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "btn btn_dark",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.doDeleteAsset.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n            确认\n          ")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }