var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "v_ar_edit_editor",
    },
    [
      _c("div", {
        staticClass: "editor",
        attrs: { id: "editor" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.showDialog.apply(null, arguments)
          },
        },
      }),
      _c(
        "modal",
        { attrs: { name: "edit_cover", height: "auto" } },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "添加封面" },
              on: { closeHandler: _vm.closeEditCover },
            },
            [
              _vm.editSceneData
                ? _c("CoverScheme", {
                    attrs: {
                      scheme: _vm.editSceneData.scheme,
                      scene: _vm.editSceneData.scene,
                      publish: _vm.saveAndPublish,
                    },
                    on: {
                      closeHandler: _vm.closeEditCover,
                      publishHandler: _vm.openPublish,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        { attrs: { name: "publishDialog", height: "auto", width: "300px" } },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "提示" },
              on: { closeHandler: _vm.closePublish },
            },
            [
              _vm.editSceneData
                ? _c("PublishDialog", {
                    attrs: {
                      scheme: _vm.editSceneData.scheme,
                      scene: _vm.editSceneData.scene,
                      status: 1,
                    },
                    on: { closeHandler: _vm.closePublish },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "newArScheme",
            height: "auto",
            width: "300px",
            clickToClose: false,
          },
        },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "提示" },
              on: {
                closeHandler: function ($event) {
                  return _vm.$modal.hide("newArScheme")
                },
              },
            },
            [
              _c("div", { staticClass: "change_ar_editor_group" }, [
                _c("div", { staticClass: "describe" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.changeEditorDescribe) +
                      "\n        "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "fun_group d-flex align-center justify-center",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "btn btn_light",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$modal.hide("newArScheme")
                          },
                        },
                      },
                      [_vm._v("\n            否\n          ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn btn_dark",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.doChangeScene.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n            是\n          ")]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }