<template>
<div
  v-loading="loading"
  class="edit_asset_page"
>
  <div
    class="line_group d-flex align-center "
  >
    <div
      class="key"
    >
      名称:
    </div>
    <input
      v-model="name"
      type="text"
      class="input"
      placeholder="请输入名称"
    >
  </div>
  <div
    class="line_group d-flex align-center "
  >
    <div
      class="key"
    >
      上传文件:
    </div>
    <div
      v-show="!filename"
      class="btn btn_dark cp"
      @click.stop="touchUpload"
    >
      点击上传
    </div>
    <input
      :key="inputKey"
      type="file"
      ref="file"
      @change="fileChange"
      :accept="acceptType"
      style="display: none;"
    >
    <div
      v-show="filename"
      class="file_name_group d-flex align-center "
    >
      <div
        class="file_name"
      >
        {{ filename }}
      </div>
      <div
        class="del cp"
        @click.stop="clearFile"
      >
        x
      </div>
    </div>
  </div>
  <div
    v-show="type !== ''"
    class="hint"
    :class="{
      error: typeErr
    }"
  >
    {{ typeText }}
  </div>
  <div
    v-show="type === 4"
    class="line_group d-flex align-center "
  >
    <div
      class="key"
    >
      封面图:
    </div>
    <div
      v-show="!pic"
      class="btn btn_dark cp"
      @click.stop="touchUploadPic"
    >
      点击上传
    </div>
    <input
      type="file"
      ref="pic"
      @change="picChange"
      accept="image/*"
      style="display: none;"
    >
    <div
      v-show="pic"
      class="file_name_group d-flex align-center "
    >
      <div
        class="file_name"
      >
        {{ pic }}
      </div>
      <div
        class="del cp"
        @click.stop="clearPic"
      >
        x
      </div>
    </div>
  </div>
  <div
    class="fun_group d-flex align-center justify-center"
  >
    <div
      class="btn btn_light"
      @click.stop="doCancel"
    >
      取消
    </div>
    <div
      class="btn btn_dark"
      @click.stop="doSure"
    >
      确定
    </div>
  </div>
</div>
</template>

<script>
import oss from '@/api/modules/oss'
import tools from '@/api/tools'
import request from '@/api/request'
import utilsOss from '@/utils/oss'
export default {
  data () {
    return {
      name: '',
      pic: '',
      type: '',
      filename: '',
      filesize: '',
      url: '',
      picName: '',
      loading: false,
      videoElement: undefined,
      videoUrl: undefined,
      videoFile: undefined,
      isCapturing: false,
      coverImageData: '',
      typeText: '',
      typeErr: false,
      inputKey: 0,
    }
  },
  props: {
    inputType: {
      default: '',
    }
  },
  computed: {
    acceptType () {
      let acceptType = 'image/*,video/*,audio/*,.glb'
      if (this.inputType === 1) {
        return 'image/*'
      }
      if (this.inputType === 2) {
        return 'video/*'
      }
      if (this.inputType === 3) {
        return 'audio/*'
      }
      if (this.inputType === 4) {
        return '.glb'
      }
      return acceptType
    }
  },
  mounted () {
    //console.log(this.inputType)
  },
  watch: {
    inputType: function (n, o) {
      //console.log(n, o)
      this.inputKey ++
    }
  },
  methods: {
    async picChange () {
      const file = this.$refs.pic.files[0]
      this.loading = true
      const res = await this.autoUpload(file)
      this.loading = false
        this.pic = res
      this.picName = res
    },
    touchUploadPic () {
      if (this.$refs.pic) {
        this.$refs.pic.click()
      }
    },
    clearFile () {
      this.filename = ''
      this.filesize = ''
      this.url = ''
      this.type = ''
      this.$refs.file.value = null
      this.$refs.file.value = ''
      this.clearPic()
    },
    clearPic () {
      this.$refs.pic.value = null
      this.$refs.pic.value = ''
      this.pic = ''
      this.picName = ''
    },
    async fileChange () {
      //console.log(this.$refs.file.files[0])
      const file = this.$refs.file.files[0]
      const extName = tools.getRealExtName(file.name)
      //console.log(extName)
      const type = this.getType(extName)
      //console.log(type)
      if (type === 0 ) {
        return
      }
      this.type = type
      this.loading = true
      const res = await this.autoUpload(file)
      this.loading = false
      this.url = res
      this.filename = file.name
      this.filesize = file.size
      if (this.type === 2) {
        this.loading = true
        await this.getVideoCapture(file)
        this.loading = false
      }
      //console.log(this.filename, this.filesize)
    },
    async getVideoCapture (file) {
      this.$set(
        this,
        'videoFile',
        file
      )
      this.videoURL = URL.createObjectURL(file)
      this.$set(
        this,
        'videoElement',
        document.createElement('video')
      )
      this.videoElement.src = this.videoURL
      await this.playAndCaptureSecondFrame()
    },
    async playAndCaptureSecondFrame () {
      if (!this.videoElement || !this.videoURL) return
      this.isCapturing = true
      await this.videoElement.play()

      // 等待足够的时间让视频播放到第二帧
      // 这可能需要根据视频的帧率进行调整
      await new Promise((resolve) => {
        setTimeout(resolve, 1000 / this.videoElement.playbackRate)
      })

      await this.captureFrame()
    },
    async captureFrame () {
      if (!this.videoElement || !this.videoElement.readyState > 0) return
      const canvas = document.createElement('canvas')
      canvas.width = this.videoElement.videoWidth
      canvas.height = this.videoElement.videoHeight
      const ctx = canvas.getContext('2d')
      ctx?.drawImage(this.videoElement, 0, 0, canvas.width, canvas.height)
      this.coverImageData = canvas.toDataURL('image/png')
      this.isCapturing = false
      this.videoElement.pause() // 暂停视频以防止继续播放
      // //console.log(this.coverImageData)
      //console.log(this.base64ToFile(this.coverImageData))
      const url = await this.base64ToFile(this.coverImageData)
      this.pic = url
    },
    async base64ToFile (base64) {
      // 从Base64字符串中提取数据部分（去掉"data:image/jpeg;base64,"）
      const base64Data = base64.replace(/^data:image\/\w+;base64,/, "")
      // 使用上面的函数解码Base64为ArrayBuffer
      const arrayBuffer = this.base64ToArrayBuffer(base64Data)

      // 创建一个Blob对象
      const blob = new Blob([arrayBuffer], { type: 'image/png' }) // 注意：这里假设图片是JPEG格式，根据实际情况修改
      //console.log(blob)
      // const url = await this.uploadFile(blob)
      const url = await oss.uploadFile({
        file: blob,
        type: 'file',
        name: utilsOss.generateName({
          file: {
            name: 'pic.png'
          }
        })
      })
      //console.log(url)
      return url
      // //console.log({ res })
      // return res
    },
    // 将Base64解码为Uint8Array
    base64ToArrayBuffer (base64) {
      const binary_string = window.atob(base64)
      const len = binary_string.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
      }
      return bytes.buffer
    },
    touchUpload () {
      if (this.$refs.file) {
        this.$refs.file.click()
      }
    },
    async autoUpload (file) {
      const res = await oss.uploadFile({
        file
      })
      return res
    },
    getType (extName) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
      if (imageExtensions.includes(extName)) {
        this.typeErr = false
        this.typeText = '图片类型'
        return 1
      }
      const videoExtensions = ['mp4', 'avi', 'mov', 'rmvb', 'rm', 'flv', '3gp', 'webm', 'mkv', 'ts', 'm4v']
      if (videoExtensions.includes(extName)) {
        this.typeErr = false
        this.typeText = '视频类型'
        return 2
      }
      const audioExtensions = ['mp3', 'wav', 'ogg', 'flac', 'aac', 'm4a', 'wma', 'au', 'aif', 'aiff', 'caf', 'mka', 'ra', 'ram', 'snd']
      if (audioExtensions.includes(extName)) {
        this.typeErr = false
        this.typeText = '音频类型'
        return 3
      }
      const threeDExtensions = ['obj', 'stl', 'fbx', 'dae', 'gltf', 'glb'];
      if (threeDExtensions.includes(extName)) {
        this.typeErr = false
        this.typeText = '3D类型'
        return 4
      }
      this.typeErr = true
      this.typeText = '类型错误'
      return 0
    },
    doCancel () {
      this.$emit('closeHandler')
    },
    async doSure () {
      if (this.name.trim() === '') {
        this.$notice({
          desc: '请输入名称',
          tpye: 'error'
        })
        return
      }
      if (this.url === '') {
        this.$notice({
          desc: '请上传文件',
          tpye: 'error'
        })
        return
      }
      if (this.type === 4 && this.pic === '') {
        this.$notice({
          desc: '请上传封面图',
          tpye: 'error'
        })
        return
      }
      const res = await this.doCreate()
      if (res) {
        this.$emit('reloadHandler')
        this.doCancel()
      }
    },
    async doCreate () {
      if (this.loading) {
        return
      }
      this.loading = true
      const res = await request.request(
        '/vr/createResource',
        {
          name: this.name,
          pic: this.pic,
          type: this.type,
          filename: this.filename,
          filesize: this.filesize / 1024,
          url: this.url,
        }
      )
      this.loading = false
      if (res.message === 'success') {
        this.$notice({
          desc: '上传成功',
          tpye: 'success'
        })
        return true
      }
      return false
    },
  }
}
</script>

<style scoped lang="scss">
.edit_asset_page {
  width: 100%;
  padding-top: 20px;
  .line_group {
    padding: 10px 20px;
    .key {
      font-size: 14px;
      padding-right: 20px;
      width: 60px;
      flex-shrink: 0;
    }
    .input {
      width: 100%;
      font-size: 14px;
      border: none;
      border-bottom: 1px solid #ccc;
      padding: 0 8px;
      color: #333;
      outline: none;
      line-height: 30px;
    }

    .btn {
      font-size: 13px;
      padding: 0 10px;
      line-height: 30px;
      border-radius: 4px;
    }
    .file_name_group {
      width: 100%;
      overflow: auto;
      .file_name {
        @include single_line_intercept;
        width: 100%;
      }
      .del {
        cursor: pointer;
        margin-left: 10px;
        line-height: 20px;
        user-select: none;
        font-weight: bold;
        width: 20px;
        flex-shrink: 0;
      }
    }
  }
  .hint {
    font-size: 12px;
    padding-left: 100px;
    padding-bottom: 20px;
    color: #999;
    width: 100%;
    &.errpr {
      color: indianred;
    }
  }
  .fun_group {
    padding: 30px 0 20px;
    .btn {
      cursor: pointer;
      font-size: 15px;
      line-height: 35px;
      border-radius: 4px;
      margin: 0 10px;
      padding: 0 18px;
    }
  }
}
</style>
