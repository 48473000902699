<script>
// import '@/utils/FuMeta/EditorSdk240604'
import '@/utils/FuMeta/EditorSdk240702'
import CoverScheme from '@/views/ArgumentedReality/Common/CoverScheme'
import store from '@/store'
import { mapState } from 'vuex'
import JsModal from '@/components/JsModal'
import PublishDialog from '@/views/ArgumentedReality/Scheme/Dialog/Publish'
import ARScheme from '@/classes/modules/AR/ARScheme'
/**
 * 实例化编辑器
 * @param {String} id 页面容器的id
 * @param {String} apiKey 使用授权相关的key
 * @param {Object} config 菜单、工具配置，系统会默认显示一组菜单和工具，你也可以增加自定义
 * */
const editorConfig = {
  'menuTool': [
    // 提供系统默认菜单，可通过value：ture或false来控制菜单显隐
    {
      code: 'undo', // 撤销
      value: true
    },
    {
      code: 'redo', // 重做
      value: true
    },
    {
      code: 'save', // 保存
      value: true
    },
    {
      code: 'overview', // 预览
      value: false,
      enable: false
    },
    /* 自定义菜单 */
    {
      // icon: "https://www.ifumeta.com/group2/default/20240311/14/44/6/帮助常态.png?name=%E5%B8%AE%E5%8A%A9%E5%B8%B8%E6%80%81.png&download=1",
      icon: 'https://mixture-attach.ourutec.com/pmcs/2024/07/01/1719821162364qpqbsr.png',
      // icon: 'https://qcc-static.qichacha.com/qcc/pc-web/prod-24.06.143/images/logo-03617c0b.png',
      // activeIcon: "https://www.ifumeta.com/group2/default/20240311/14/45/6/帮助鼠标.png?name=%E5%B8%AE%E5%8A%A9%E9%BC%A0%E6%A0%87.png&download=1",
      activeIcon: 'https://mixture-attach.ourutec.com/pmcs/2024/07/01/1719821162364qpqbsr.png',
      title: '发布',

      code: 'menuToolTest',
      value: true
    },
  ],
  'menubar': [
    // 提供系统默认菜单，可通过value：ture或false来控制菜单显隐
    {
      code: 'lighting', // 灯光
      value: true
    },
    {
      code: 'arSet', // ar设置
      value: false
    },
    {
      code: 'renderSet', // 渲染设置
      value: true
    },
    {
      code: 'help', // 帮助
      value: false
    },
  ]
}

'https://mixture-attach.ourutec.com/pmcs/2024/07/01/1719822004838siswru.png'

export default {
  components: {
    CoverScheme,
    JsModal,
    PublishDialog,
  },
  data () {
    return {
      editor: undefined,
      // centerDialogVisible: false,
      loading: false,
      schemeNum: undefined,
      currentSchemeUuid: undefined,
      currentSchemeNum: undefined,

      changeEditorDescribe: '当前场景未保存, 是否切换?',
      changeSceneData: undefined,

      saveAndPublish: false, // 保存并发布
    }
  },
  computed: {
    ...mapState({
      editSceneData: state => state.ar.editSceneData,
    }),
  },
  async mounted () {
    this.loading = true
    // 新增方案按钮
    if (this.editSceneData && this.editSceneData.scheme && this.editSceneData.scheme.plancode) {
      editorConfig.menuTool.unshift({
        icon: 'https://mixture-attach.ourutec.com/pmcs/2024/07/01/1719822004838siswru.png',
        activeIcon: 'https://mixture-attach.ourutec.com/pmcs/2024/07/01/1719822004838siswru.png',
        title: '新增方案',

        code: 'newScheme',
        value: true
      })
    }

    console.log(editorConfig.menuTool)

    /**
     * 实例化编辑器
     * @param {String} id 页面容器的id
     * @param {String} apiKey 使用授权相关的key
     * @param {Object} config 菜单、工具配置，系统会默认显示一组菜单和工具，你也可以增加自定义
     * */
    // 21c422d54b814392930d71c6ad112bcf
    const apiKey = '21c422d54b814392930d71c6ad112bcf'
    const editor = new window.Editor({
      id: 'editor',
      ak: apiKey,
      config: editorConfig,
    })
    this.editor = editor

    const that = this
    // 监听编辑器准备完毕
    editor.onReady(async function (instance) {
      console.log(instance)
      that.loading = true
      store.commit(
        'setAppLoading',
        true
      )
      const projectId = await editor.project.initProject()
      store.commit(
        'setAppLoading',
        false
      )
      that.loading = false
      const currentSchemeUuid = await editor.scenes.addScene()
      that.currentSchemeUuid = currentSchemeUuid
      console.log(currentSchemeUuid)
      if (that.editSceneData && that.editSceneData.scheme && that.editSceneData.scheme.plancode) {
        console.log(that.editSceneData.scheme.plancode)
        console.log(typeof that.editSceneData.scheme.plancode)
          store.commit(
            'setAppLoading',
            true
          )
          const currentSchemeNum = await editor.project.loadProject(JSON.parse(that.editSceneData.scheme.plancode))
          that.currentSchemeNum = currentSchemeNum
          // await editor.scenes.activeSceneByIndex(0)
          store.commit(
            'setAppLoading',
            false
          )
          console.log(currentSchemeNum)
      }
      store.commit(
        'setArSdkEditor',
        editor
      )
    })


    editor.onClick(async function (instance, data) {
      const { type, res } = data
      console.log('type：' + type + '，res：' + res)
      console.log(data)
      console.log(typeof data)
      let plancodeRes
      switch(type) {
        case 'undo':
          console.log('...todo')
          break;
        case 'redo':
          console.log('...todo')
          break;
        case 'save':
          that.saveAndPublish = false
          console.log('...todo')
          console.log('hhh')
          that.editSceneData.scheme.plancode = res

          if (that.editSceneData.scheme.id) {
            console.log(that.editSceneData.scheme)
            const updateRes = await that.editSceneData.scheme.save({
              plancode: res
            })
            if (updateRes.message === 'success') {
              that.$notice({
                desc: `保存成功`,
                type: 'success'
              })
              that.editSceneData.save = true
            }
          } else {
            that.$modal.show('edit_cover')
          }
          // that.centerDialogVisible = true
          break
        case 'menuToolTest':
          console.log('...todo')
          that.loading = true
          plancodeRes = await editor.project.saveProject()
          that.loading = false

          // editor.
          that.editSceneData.scheme.plancode = plancodeRes
          console.log(that.editSceneData.scheme)
          if (that.editSceneData.scheme.id) {
            that.saveAndPublish = false
            console.log(that.editSceneData.scheme)
            // that.$modal.show('publishDialog')
            that.$modal.show('publishDialog')
          } else {
            that.saveAndPublish = true
            // that.editSceneData.scheme.status = 1
            that.$modal.show('edit_cover')
          }
          break
        case 'newScheme':

          that.$set(that,'changeSceneData', that.editSceneData.scene)
          if (!that.editSceneData.save) {
            that.$modal.show('newArScheme')
            console.log('未保存')
            return
          } else {
            console.log('已保存')
            await that.addNewScheme()
          }
          break
        default: break
      }
    })

    // setTimeout(() => {
    //   // editor.
    // })
  },
  destroyed () {
    console.log('离开')
    store.commit(
      'setArEditSceneData',
      undefined
    )
  },
  methods: {
    async addNewScheme () {
      this.loading = true
      store.commit(
        'setAppLoading',
        true
      )
      await this.editor.project.initProject()
      store.commit(
        'setAppLoading',
        false
      )
      await this.editor.scenes.addScene()
      const scene = this.changeSceneData
      store.commit(
        'setArEditSceneData',
        {
          scene,
          scheme: new ARScheme({
            modelId: scene.id,
          }),
          save: false,
        }
      )
      this.loading = true
      await this.editor.loader.loadVpsBaseboardByUrl(
        scene.fid,
        scene.resUrl
      )
      this.loading = false
    },
    async doChangeScene () {
      await this.addNewScheme()
      this.$modal.hide('newArScheme')
    },
    showDialog () {
      this.$modal.show('edit_cover')
    },
    closeEditCover () {
      this.$modal.hide('edit_cover')
    },
    closePublish () {
      this.$modal.hide('publishDialog')
    },
    openPublish () {
      this.$modal.show('publishDialog')
    }
  }

}
</script>

<template>
  <div
    v-loading="loading"
    class="v_ar_edit_editor"
  >
    <div
      class="editor"
      id="editor"
      @click.stop="showDialog"
    >
      <!--editor-->
    </div>
    <modal
      name="edit_cover"
      height="auto"
    >
      <JsModal
        :title="'添加封面'"
        @closeHandler="closeEditCover"
      >
        <CoverScheme
          v-if="editSceneData"
          :scheme="editSceneData.scheme"
          :scene="editSceneData.scene"
          :publish="saveAndPublish"
          @closeHandler="closeEditCover"
          @publishHandler="openPublish"
        ></CoverScheme>
      </JsModal>
    </modal>
    <modal
      name="publishDialog"
      height="auto"
      width="300px"
    >
      <JsModal
        :title="'提示'"
        @closeHandler="closePublish"
      >
        <PublishDialog
          v-if="editSceneData"
          :scheme="editSceneData.scheme"
          :scene="editSceneData.scene"
          :status="1"
          @closeHandler="closePublish"
        ></PublishDialog>
      </JsModal>
    </modal>
    <modal
      name="newArScheme"
      height="auto"
      width="300px"
      :clickToClose="false"
    >
      <JsModal
        :title="'提示'"
        @closeHandler="$modal.hide('newArScheme')"
      >
        <div
          class="change_ar_editor_group"
        >
          <div
            class="describe"
          >
            {{ changeEditorDescribe }}
          </div>
          <div
            class="fun_group d-flex align-center justify-center"
          >
            <div
              class="btn btn_light"
              @click.stop="$modal.hide('newArScheme')"
            >
              否
            </div>
            <div
              class="btn btn_dark"
              @click.stop="doChangeScene"
            >
              是
            </div>
          </div>
        </div>
      </JsModal>
    </modal>
  </div>
</template>

<style scoped lang="scss">
.v_ar_edit_editor {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .editor {
    width: 100%;
    height: 100%;
  }
  .change_ar_editor_group {
    padding: 20px;
    .describe {
      font-size: 16px;
      text-align: center;
    }
    .fun_group {
      padding-top: 20px;
      .btn {
        font-size: 16px;
        padding: 5px 20px;
        margin: 0 15px;
        border-radius: 2px;
      }
    }
  }
}
</style>
