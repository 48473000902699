<template>
<div
  class="v_ar_edit_index d-flex align-center"
>
  <div
    class="list_group flex-shrink-0 bbox"
  >
    <SceneList
      v-if="pageType === 'scene'"
    ></SceneList>
    <AssetList
      v-if="pageType === 'asset'"
    ></AssetList>
  </div>
  <div
    class="edit_group"
  >
    <Editor></Editor>
  </div>
</div>
</template>

<script>
import SceneList from '@/views/ArgumentedReality/Edit/SceneList'
import AssetList from '@/views/ArgumentedReality/Edit/AssetList'
import Editor from '@/views/ArgumentedReality/Edit/Editor'
import { mapState } from 'vuex'

export default {
  components: {
    SceneList,
    AssetList,
    Editor,
  },
  computed: {
    ...mapState({
      pageType: state => state.ar.pageType
    })
  },
  mounted () {
    console.log(this.$route)
  }
}
</script>

<style scoped lang="scss">
.v_ar_edit_index {
  width: 100%;
  height: 100%;
  overflow: auto;
  .list_group {
    @include scrollbar;
    height: 100%;
    overflow: auto;
    width: 260px;
    background-color: #fff;
    padding: 10px;
    border-right: 1px solid #f4f4f4;
  }
  .edit_group {
    width: 100%;
    height: 100%;
  }
}
</style>
