<script>
import store from '@/store'
import { mapState } from 'vuex'
import AssetComponent from '@/views/ArgumentedReality/Edit/Asset'
import { listForClasses } from '@/classes'
import ARAsset from '@/classes/modules/AR/ARAsset'
import request from '@/api/request'
import ArPackage from '@/classes/modules/AR/ArPackage'
import EditAsset from '@/views/ArgumentedReality/Edit/EditAsset'
import JsModal from '@/components/JsModal'
export default {
  components: {
    AssetComponent,
    JsModal,
    EditAsset,
  },
  data () {
    return {
      assetType: [
        {
          type: 'model',
          name: '模型'
        },
        {
          type: 'pic',
          name: '图片',
        },
        {
          type: 'video',
          name: '视频',
        },
        {
          type: 'audio',
          name: '音频'
        }
      ],
      type: 'model',
      loading: false,
      hasMore: false,
      page: undefined,
      filterType: 0,
      filterValue: 'all',
      arPackagePageNumber: 1,
      arPackageList: [
        {
          id: 'all',
          name: '全部',
        }
      ],
      deleteData: undefined,
      loadingDelete: false,
    }
  },
  computed: {
    ...mapState({
      assetList: state => state.ar.assetList,
      sdkEditor: state => state.ar.sdkEditor,
      editSceneData: state => state.ar.editSceneData,
    }),
    typeNumber () {
      let typeNumber = ''
      switch (this.type) {
        case 'model' :
          typeNumber = 4
          break
        case 'pic' :
          typeNumber = 1
          break
        case 'video' :
          typeNumber = 2
          break
        case 'audio' :
          typeNumber = 3
          break
        default:
          typeNumber = ''
          break
      }
      return typeNumber
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      await this.loadList()
      await this.getVrPackageList()

      console.log(this.arPackageList)
    },
    async loadList () {
      const res = await this.getList()
      if (res.message === 'success') {
        store.commit(
          'setArAssetList',
          listForClasses({
            list: res.contents.page.result,
            classes: ARAsset
          })
        )
      }
    },
    async getList () {
      if (this.loading) {
        return
      }
      this.loading = true
      const data = {
        type: this.typeNumber,
        pn: 1
      }
      if (this.page && this.page.pageNo) {
        data.pn = this.page.pageNo + 1
      }
      if (this.filterValue !== 'all') {
        data.packageId = this.filterValue
      }
      const res = await store.dispatch(
        'getARAssetList',
        data
      )
      this.loading = false
      if (res.message === 'success') {
        this.$set(
          this,
          'page',
          res.contents.page
        )
        if (res.contents.page.result && res.contents.page.result.length === 20) {
          this.hasMore = true
        } else {
          this.hasMore = false
        }
        const assetList = [
          ...this.assetList,
          ...listForClasses({
            list: res.contents.page.result,
            classes: ARAsset
          })
        ]
        store.commit(
          'setArAssetList',
          assetList
        )
      }
      return res
    },
    clearList () {
      store.commit('setArAssetList', [])
      this.hasMore = false
      this.$set(
        this,
        'page',
        undefined
      )
      // store.commit('setArScenePage', {})
    },
    touchType (type) {
      if (this.type === type) {
        this.type = ''
      } else {
        this.type = type
      }
      this.clearList()
      if (this.filterType === 0) {
        this.getList()
      } else {
        this.getPersonalList()
      }
    },
    loadMore () {
      this.getList()
    },
    async touchAsset (asset) {
      console.log(asset)
      console.log(this.sdkEditor)
      console.log(this.editSceneData)
      if (!this.editSceneData || !this.editSceneData.scene) {
        this.$notice({
          desc: `请先选择场景`,
          type: 'error'
        })
        return
      }
      if (!this.sdkEditor) {
        this.$notice({
          desc: `编辑器错误`,
          type: 'error'
        })
        return
      }
      this.addAsset(asset)
    },
    async addAsset (asset) {
      const {
        type,
        url,
        pic,
      } = asset
      this.editSceneData.save = false
      // 图片
      if ( type === 1) {
        this.loading = true
        console.log(`${ this.$file_url }${ url }`)
        await this.sdkEditor.loader.loadImage(
          `${ this.$file_url }${ url }`
        )
        this.loading = false
        return
      }
      // 视频
      if ( type === 2) {
        this.loading = true
        await this.sdkEditor.loader.loadVideo(
          `${ this.$file_url }${ url }`,
          `${ this.$file_url }${ pic }`,
        )
        this.loading = false
        return
      }
      // 音频
      if ( type === 3) {
        this.loading = true
        await this.sdkEditor.loader.loadAudio(
          `${ this.$file_url }${ url }`
        )
        this.loading = false
        return
      }
      // 3d
      if ( type === 4) {
        this.loading = true
        console.log(`${ this.$file_url }${ url }`)
        await this.sdkEditor.loader.loadModelByUrl(
          `${ this.$file_url }${ url }`
        )
        this.loading = false
        return
      }
    },
    async getVrPackageList () {
      if (this.loading) {
        return
      }
      this.loading = true
      const  data = {
        pn: this.arPackagePageNumber
      }
      const res = await request.request(
        '/vr/myPackageList',
        data
      )
      this.loading = false
      if (res.message === 'success') {
        const { contents } = res
        const { page } = contents
        const { result } = page
        if (result && result.length) {
          const list = listForClasses({
            list: result,
            classes: ArPackage
          })
          // console.log(list)
          this.arPackageList = [
            ...this.arPackageList,
            ...list
          ]
          if (list.length === 20) {
            this.arPackagePageNumber ++
            await this.getVrPackageList()
          }
        }
      }
    },
    async changePackage (v) {
      console.log(v)
      this.clearList()
      await this.getList()
    },
    async getPersonalList () {
      if (this.loading) {
        return
      }
      this.loading = true
      const data = {
        type: this.typeNumber,
        pn: 1
      }
      if (this.page && this.page.pageNo) {
        data.pn = this.page.pageNo + 1
      }
      const res = await request.request(
        '/vr/myownResourceList',
        data
      )
      this.loading = false
      if (res.message === 'success') {
        this.$set(
          this,
          'page',
          res.contents.page
        )
        if (res.contents.page.result && res.contents.page.result.length === 20) {
          this.hasMore = true
        } else {
          this.hasMore = false
        }
        const assetList = [
          ...this.assetList,
          ...listForClasses({
            list: res.contents.page.result,
            classes: ARAsset
          })
        ]
        store.commit(
          'setArAssetList',
          assetList
        )
      }
      return res
    },
    async changeFilterType (value) {
      if (this.filterType === value) {
        return
      }
      this.filterType = value
      console.log(this.filterType)
      this.clearList()
      // this.type = '' // 清空状态
      if (this.filterType === 0) {
        this.getList()
      } else {
        this.getPersonalList()
      }
    },
    touchEditAsset () {
      console.log('触发上传')
      this.$modal.show('editAsset')
    },
    closeEditAsset () {
      this.$modal.hide('editAsset')
    },
    reloadPersonalList () {
      this.clearList()
      this.getPersonalList()
    },
    touchDeleteHandler (params) {
      this.$set(
        this,
        'deleteData',
        params
      )
      this.$modal.show('deleteAsset')
    },
    closeDeleteAsset () {
      this.$modal.hide('deleteAsset')
    },
    async doDeleteAsset () {
      if (this.loadingDelete) {
        return
      }
      const { deleteData } = this
      const { id } = deleteData
      this.loadingDelete = true
      const res = await request.request(
        '/vr/deleteResource',
        {
          id
        }
      )
      this.loadingDelete = false
      if (res.message === 'success') {
        this.$notice({
          desc: '删除成功',
          tpye: 'success'
        })
        this.reloadPersonalList()
        this.closeDeleteAsset()
      }
    },

  }
}
</script>

<template>
<div
  v-loading="loading"
  class="v_ar_edit_asset_list"
>

  <div
    v-if="0"
    class="upload_group"
  >
    <div
      class="upload_btn btn_dark"
    >
      点击上传
    </div>
  </div>
  <div
    class="type_list d-flex align-center justify-space-between"
  >
    <template
      v-for="(item, index) in assetType"
    >
      <div
        :key="index"
        class="type_group d-flex align-center justify-center flex-column cp"
        :class="{
          [item.type]: true,
          on: item.type === type
        }"
        @click.stop="touchType(item.type)"
      >
        <div
          class="icon"
        ></div>
        <div
          class="name"
        >
          {{ item.name }}
        </div>
      </div>
    </template>
  </div>
  <div
    v-if="1"
    class="filter_group d-flex align-center justify-space-between flex-shrink-0"
  >
    <div
      class="public tag"
      :class="{
        active: filterType === 0
      }"
      @click.stop="changeFilterType(0)"
    >
      公共资产
    </div>
    <div
      class="my tag"
      :class="{
        active: filterType === 1
      }"
      @click.stop="changeFilterType(1)"
    >
      我的资产
    </div>
  </div>
  <div
    v-show="!filterType"
    class="package_list_group"
  >
    <el-select
      v-model="filterValue"
      class="package_list"
      placeholder="请选择"
      size="mini"
      @change="changePackage"
    >
      <el-option
        v-for="item in arPackageList"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
  </div>

  <div
    class="asset_list_group d-flex flex-wrap align-center justify-space-between"
  >
    <div
      v-show="filterType"
      class="add_asset_group cp"
      @click.stop="touchEditAsset"
    ></div>
    <template
      v-for="(item, index) in assetList"
    >
      <div
        :key="index"
        class="asset_group"
      >
        <AssetComponent
          :ar-asset="item"
          @click.stop.native="touchAsset(item)"
          @touchDeleteHandler="touchDeleteHandler"
        ></AssetComponent>
      </div>

    </template>
  </div>
  <div
    v-if="hasMore"
    class="more_group"
  >
    <div
      class="more cp"
      @click.stop="loadMore"
    >
      加载更多
    </div>
  </div>
  <modal
    name="editAsset"
    height="auto"
    width="400px"
  >
    <JsModal
      :title="'上传资产'"
      @closeHandler="closeEditAsset"
    >
      <EditAsset
        :input-type="typeNumber"
        @closeHandler="closeEditAsset"
        @reloadHandler="reloadPersonalList"
      ></EditAsset>
    </JsModal>
  </modal>
  <modal
    name="deleteAsset"
    height="auto"
    width="400px"
  >
    <JsModal
      :title="'删除资产'"
      @closeHandler="closeDeleteAsset"
    >
      <div
        v-loading="loadingDelete"
        class="delete_asset_group"
      >
        <div
          class="desc"
        >
          是否确认删除?
        </div>
        <div
          class="fun_group d-flex align-center justify-center"
        >
          <div
            class="btn btn_light"
            @click.stop="closeDeleteAsset"
          >
            取消
          </div>
          <div
            class="btn btn_dark"
            @click.stop="doDeleteAsset"
          >
            确认
          </div>
        </div>
      </div>
    </JsModal>
  </modal>
</div>
</template>

<style scoped lang="scss">
@mixin icon ($name) {
  &.#{$name} {
    .icon {
      background-image: url(~@/assets/images/ar/asset_type/#{ $name }.png);
      background-size: 40px 40px;
    }
    &.on {
      .icon {
        background-image: url(~@/assets/images/ar/asset_type/#{ $name }_on.png);
        background-size: 56px 52px;
      }
      //&:hover {
      //  .icon {
      //    background-image: url(~@/assets/images/ar/asset_type/#{ $name }.png);
      //    background-size: 40px 40px;
      //  }
      //}
    }
    &:hover {
      .icon {
        background-image: url(~@/assets/images/ar/asset_type/#{ $name }_on.png);
        background-size: 56px 52px;
      }
    }
  }
}
.v_ar_edit_asset_list {
  .filter_group {
    padding: 10px 0;
    .tag {
      @include transition;
      font-size: 14px;
      color: #333;
      padding: 5px 20px;
      font-weight: bold;
      border-bottom: 3px solid transparent;
      user-select: none;
      cursor: pointer;
      &.active {
        color: $main_blue;
        border-bottom-color: $main_blue;
      }
      &:hover {
        color: $main_blue;
      }
    }
  }
  .package_list_group {
    width: 100%;
    .package_list {
      width: 100%;
    }
  }
  .upload_group {
    padding: 10px 0;
    .upload_btn {
      font-size: 13px;
      line-height: 30px;
      border-radius: 6px;
      text-align: center;
      width: 100%;
    }
  }
  .type_list {
    .type_group {
      @include icon(model);
      @include icon(pic);
      @include icon(video);
      @include icon(audio);
      .name {
        @include transition;
        font-size: 14px;
        color: #333;
        margin-top: 5px;
      }
      .icon {
        @include transition;
        @include background;
        width: 56px;
        height: 52px;
      }
      &:hover {
        .name {
          color: $main_blue;
        }
      }
      &.on {
        .name {
          color: $main_blue;
        }
        //&:hover {
        //  .name {
        //    color: #333;
        //  }
        //}
      }
    }
  }
  .asset_list_group {
    padding-top: 10px;
    .asset_group, .add_asset_group {
      width: 115px;
      height: 115px;
      margin: {
        bottom: 10px;
      }
    }
    .add_asset_group {
      background-color: #f2f2f2;
      position: relative;
      border-radius: 3px;
      &:after  {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #aaa;
        width: 5px;
        height: 31px;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #aaa;
        width: 31px;
        height: 5px;
      }
    }
  }
  .more_group {
    width: 100%;
    padding: 10px 0;
    .more {
      text-align: center;
      color: $main-blue;
      font-size: 13px;
    }
  }
  .delete_asset_group {
    .desc {
      font-size: 16px;
      padding: 20px 20px 10px;
    }
    .fun_group {
      padding: 10px 20px 20px;
      .btn {
        cursor: pointer;
        font-size: 14px;
        line-height: 36px;
        border-radius: 4px;
        padding: 0 20px;
        margin: 0 8px;
      }
    }
  }
}
</style>
