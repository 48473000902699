var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "v_ar_scene_list",
    },
    [
      _vm._l(_vm.sceneList, function (item, index) {
        return [
          _c(
            "el-tooltip",
            {
              key: index,
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: item.name,
                placement: "bottom",
                "open-delay": 2000,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "scene_group cp",
                  class: {
                    active:
                      _vm.editSceneData &&
                      _vm.editSceneData.scene &&
                      _vm.editSceneData.scene.id === item.id,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.touchScene(item)
                    },
                  },
                },
                [
                  item.pic.includes("http")
                    ? _c("div", {
                        staticClass: "bg",
                        style: {
                          backgroundImage: `url(${item.pic})`,
                        },
                      })
                    : _c("div", {
                        staticClass: "bg",
                        style: {
                          backgroundImage: `url(${_vm.$file_url}${item.pic})`,
                        },
                      }),
                  _c("div", { staticClass: "name_group bbox" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(
                        "\n            " + _vm._s(item.name) + "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "hover_grouo" }, [
                    _vm._v("\n          选择\n        "),
                  ]),
                ]
              ),
            ]
          ),
        ]
      }),
      _vm.hasMore
        ? _c(
            "div",
            {
              staticClass: "more_group cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.loadMore.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n    加载更多\n  ")]
          )
        : _vm._e(),
      _c(
        "modal",
        {
          attrs: {
            name: "changeArEditor",
            height: "auto",
            width: "300px",
            clickToClose: false,
          },
        },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "提示" },
              on: {
                closeHandler: function ($event) {
                  return _vm.$modal.hide("changeArEditor")
                },
              },
            },
            [
              _c("div", { staticClass: "change_ar_editor_group" }, [
                _c("div", { staticClass: "describe" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.changeEditorDescribe) +
                      "\n        "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "fun_group d-flex align-center justify-center",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "btn btn_light",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$modal.hide("changeArEditor")
                          },
                        },
                      },
                      [_vm._v("\n            否\n          ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn btn_dark",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.doChangeScene.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n            是\n          ")]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }