var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v_ar_edit_asset" }, [
    _vm.arAsset.userId
      ? _c("div", {
          staticClass: "del cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.touchDel.apply(null, arguments)
            },
          },
        })
      : _vm._e(),
    _c("div", { staticClass: "img_group" }, [
      _vm.imgUrl
        ? _c("div", {
            staticClass: "bg",
            style: {
              backgroundImage: `url(${_vm.imgUrl})`,
            },
          })
        : _vm._e(),
      _vm.showAudioBg ? _c("div", { staticClass: "audio_bg" }) : _vm._e(),
    ]),
    _c("div", { staticClass: "touch_group bbox" }, [
      _c("div", { staticClass: "name bbox" }, [
        _vm._v("\n      " + _vm._s(_vm.arAsset.name) + "\n    "),
      ]),
      _c("div", { staticClass: "touch" }, [_vm._v("\n      选择\n    ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }