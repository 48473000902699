var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v_ar_edit_index d-flex align-center" }, [
    _c(
      "div",
      { staticClass: "list_group flex-shrink-0 bbox" },
      [
        _vm.pageType === "scene" ? _c("SceneList") : _vm._e(),
        _vm.pageType === "asset" ? _c("AssetList") : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "edit_group" }, [_c("Editor")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }