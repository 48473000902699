/**
 * 元基建场景
 */
class ARAsset {
  createAdminName
  createTime
  createUserid
  filename
  filesize
  id
  name
  owntype
  pic
  type
  url
  userId

  constructor (params) {
    const {
      createAdminName,
      createTime,
      createUserid,
      filename,
      filesize,
      id,
      name,
      owntype,
      pic,
      type,
      url,
      userId,
    } = params ?? {}

    this.createAdminName = createAdminName
    this.createTime = createTime
    this.createUserid = createUserid
    this.filename = filename
    this.filesize = filesize
    this.id =  id
    this.name = name
    this.owntype = owntype
    this.pic = pic
    this.type = type
    this.url = url
    this.userId = userId
  }
}

export default ARAsset
