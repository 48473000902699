var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "edit_asset_page",
    },
    [
      _c("div", { staticClass: "line_group d-flex align-center" }, [
        _c("div", { staticClass: "key" }, [_vm._v("\n      名称:\n    ")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name",
            },
          ],
          staticClass: "input",
          attrs: { type: "text", placeholder: "请输入名称" },
          domProps: { value: _vm.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.name = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "line_group d-flex align-center" }, [
        _c("div", { staticClass: "key" }, [_vm._v("\n      上传文件:\n    ")]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.filename,
                expression: "!filename",
              },
            ],
            staticClass: "btn btn_dark cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.touchUpload.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n      点击上传\n    ")]
        ),
        _c("input", {
          key: _vm.inputKey,
          ref: "file",
          staticStyle: { display: "none" },
          attrs: { type: "file", accept: _vm.acceptType },
          on: { change: _vm.fileChange },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.filename,
                expression: "filename",
              },
            ],
            staticClass: "file_name_group d-flex align-center",
          },
          [
            _c("div", { staticClass: "file_name" }, [
              _vm._v("\n        " + _vm._s(_vm.filename) + "\n      "),
            ]),
            _c(
              "div",
              {
                staticClass: "del cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clearFile.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n        x\n      ")]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type !== "",
              expression: "type !== ''",
            },
          ],
          staticClass: "hint",
          class: {
            error: _vm.typeErr,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.typeText) + "\n  ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === 4,
              expression: "type === 4",
            },
          ],
          staticClass: "line_group d-flex align-center",
        },
        [
          _c("div", { staticClass: "key" }, [_vm._v("\n      封面图:\n    ")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.pic,
                  expression: "!pic",
                },
              ],
              staticClass: "btn btn_dark cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.touchUploadPic.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      点击上传\n    ")]
          ),
          _c("input", {
            ref: "pic",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.picChange },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pic,
                  expression: "pic",
                },
              ],
              staticClass: "file_name_group d-flex align-center",
            },
            [
              _c("div", { staticClass: "file_name" }, [
                _vm._v("\n        " + _vm._s(_vm.pic) + "\n      "),
              ]),
              _c(
                "div",
                {
                  staticClass: "del cp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clearPic.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        x\n      ")]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "fun_group d-flex align-center justify-center" },
        [
          _c(
            "div",
            {
              staticClass: "btn btn_light",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doCancel.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      取消\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "btn btn_dark",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doSure.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      确定\n    ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }