<script>
import ARAsset from '@/classes/modules/AR/ARAsset'
export default {
  data () {
    return {

    }
  },
  props: {
    arAsset: {
      type: ARAsset,
      default: undefined
    }
  },
  mounted () {
  },
  computed: {
    imgUrl () {
      const {
        pic,
        url,
        type
      } = this.arAsset ?? {}
      let imgUrl = ''
      switch (Number(type)) {
        case 1:
          imgUrl = `${ this.$file_url }${ url }`
          break
        case 2:
          imgUrl = `${ this.$file_url }${ pic }`
          break
        case 3:
          imgUrl = ``
          break
        case 4:
          imgUrl = `${ this.$file_url }${ pic }`
          break
        default:
          imgUrl = `${ this.$file_url }${ url }`
          break
      }
      // console.log((Number(type)), imgUrl)
      return imgUrl
    },
    showAudioBg () {
      const {
        type
      } = this.arAsset ?? {}
      return Number(type) === 3
    },

  },
  methods: {
    touchDel () {
      this.$emit(
        'touchDeleteHandler',
        this.arAsset
      )
    }
  }
}
</script>

<template>
<div
  class="v_ar_edit_asset"
>
  <div
    v-if="arAsset.userId"
    class="del cp"
    @click.stop="touchDel"
  ></div>
  <div
    class="img_group"
  >
    <div
      v-if="imgUrl"
      class="bg"
      :style="{
        backgroundImage: `url(${ imgUrl })`
      }"
    ></div>
    <div
      v-if="showAudioBg"
      class="audio_bg"
    ></div>
  </div>
  <div
    class="touch_group bbox"
  >
    <div
      class="name bbox"
    >
      {{ arAsset.name }}
    </div>
    <div
      class="touch"
    >
      选择
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_ar_edit_asset {
  position: relative;
  width: 100%;
  height: 100%;
  &:hover {
    .touch_group {
      .touch {
        opacity: 1;
      }
    }
    .del {
      opacity: 1;
    }
  }
  .del {
    @include transition;
    @include background;
    opacity: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    background: {
      image: url(~@/assets/images/common/clear_icon.png);
    }
  }
  .img_group {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    .bg {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: {
        size: cover;
        position: center;
      }
    }
    .audio_bg {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: {
        size: cover;
        position: center;
        image: url(~@/assets/images/ar/asset_type/audio_bg.png);
      }
    }
  }
  .touch_group {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 26px;
    font-size: 14px;
    .name {
      @include single_line_intercept;
      width: 100%;
      line-height: 26px;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      border-radius: 0 0 4px 4px;
      position: relative;
      z-index: 2;
      padding: 0 10px;
    }
    .touch {
      @include transition;
      width: 100%;
      line-height: 26px;
      text-align: center;
      background-color: $main_blue;
      color: #fff;
      border-radius: 0 0 4px 4px;
      position: absolute;
      z-index: 9;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
  }
}
</style>
